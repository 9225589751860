import Axios from "axios";
import token from "./setToken";

export default {
  async sumFactFindingDB(overview) {
    let params = new URLSearchParams();
    params.append("overview", JSON.stringify(overview));

    try {
      // await token.checkToken();
      const res = await Axios.post("/api/FactFindingSumDB", params, token.setTokenToHeader());
      return res;
    } catch (error) {
      console.error("冷蔵倉庫実態調査データを作成できません[sumFactFindingDB]");
      throw error;
    }
  },

  async kobetsu(targetYear) {
    let params = new URLSearchParams();
    params.append("targetYear", JSON.stringify(targetYear));

    try {
      // await token.checkToken();
      const res = await Axios.post("/api/FactFindingKobetsu", params, token.setTokenToHeader());
      return res;
    } catch (error) {
      console.error("個別返答集計データを作成できません[kobetsu]");
      throw error;
    }
  },
};

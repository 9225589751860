<template>
  <v-container>
    <v-form
      ref="form"
      v-model="valid"
      lazy-validation
    >
      <v-row class="mt-2">
        <v-col>
          <h2>{{ surveyType.filter((e) => (e.value == targetSurveyType))[0].text }} 個別返答</h2>
        </v-col>
      </v-row>

      <!-- 個別返答集計 -->
      <v-row class="">
        <v-col cols="6" sm="6" md="6" lg="6">
          <h3>個別返答返信用・個別企業集計</h3>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="auto" sm="auto" md="auto" lg="1">
          <YearPicker 
            label="集計年"
            v-model="targetYear"
            format="YYYY年"
            selectType="month"
            :readonly="false"
            :rules="[]"
            :clearable="false"
            :loading="loading"
            suffix="度"></YearPicker>
        </v-col>
        <v-col cols="3" sm="3" md="3" lg="3" class="text-center">
          <v-btn
            class="btn_font primary" 
            width="250" 
            v-on:click="kobetsuFactFinding()"
            :disabled="loading || targetYear == '' || targetYear == null"
          >
            <v-icon :small="true">mdi-file</v-icon>個別返答集計
          </v-btn>
        </v-col>
      </v-row>

      <!-- メール編集 -->
      <div v-show="kobetsuFlg">
        <v-row class="">
          <v-col cols="2" sm="2" md="2" lg="2">
            <h3>メール編集</h3>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="9" sm="9" md="9" lg="9">
            <v-text-field
              label="発信者"
              v-model="mailData.mailFrom"
              dense
              :readonly="true"
              :rules="[required]"
              :loading="loading"
            ></v-text-field>
          </v-col>
          <v-col cols="3" sm="3" md="3" lg="3" class="text-center">
            <v-btn class="btn_font primary" width="200" v-on:click="showEmailAddress(0, true, mailDestArray.tmpFromList)" :disabled="loading" ><v-icon :small="true">mdi-border-color</v-icon>発信者変更</v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="9" sm="9" md="9" lg="9">
            <v-text-field
              label="宛先"
              v-model="mailData.mailTo"
              dense
              :readonly="true"
              :rules="[]"
              :loading="loading"
            ></v-text-field>
          </v-col>
          <v-col cols="3" sm="3" md="3" lg="3" class="text-center">
            <v-btn class="btn_font primary" width="200" v-on:click="showEmailAddress(1, false, mailDestArray.tmpToList)" :disabled="loading" ><v-icon :small="true">mdi-border-color</v-icon>宛先変更</v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="9" sm="9" md="9" lg="9">
            <v-text-field
              label="CC"
              v-model="mailData.mailCC"
              dense
              :readonly="true"
              :rules="[]"
              :loading="loading"
            ></v-text-field>
          </v-col>
          <v-col cols="3" sm="3" md="3" lg="3" class="text-center">
            <v-btn class="btn_font primary" width="200" v-on:click="showEmailAddress(2, false, mailDestArray.tmpCCList)" :disabled="loading" ><v-icon :small="true">mdi-border-color</v-icon>CC変更</v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="9" sm="9" md="9" lg="9">
            <v-text-field
              label="BCC"
              v-model="mailData.mailBCC"
              dense
              :readonly="true"
              :clearable="false"
              :rules="[]"
              :loading="loading"
            ></v-text-field>
          </v-col>
          <v-col cols="3" sm="3" md="3" lg="3" class="text-center">
            <v-btn class="btn_font primary" width="200" v-on:click="showEmailAddress(3, false, mailDestArray.tmpBCCList)" :disabled="loading" ><v-icon :small="true">mdi-border-color</v-icon>BCC変更</v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="12" lg="12">
            <v-text-field
              label="件名"
              v-model="mailData.subject"
              dense
              :readonly="false"
              :clearable="false"
              :rules="[required]"
              :maxLength="100"
              counter="100"
              :loading="loading"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="12" lg="12">
            <v-textarea
              label="本文"
              v-model="mailData.body"
              dense
              :readonly="false"
              :clearable="false"
              :rules="[required]"
              :maxLength="30000"
              :loading="loading"
              counter="30000"
            ></v-textarea>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="12" lg="12">
            <v-textarea
              label="署名"
              v-model="mailData.signature"
              dense
              :readonly="false"
              :clearable="false"
              :rules="[]"
              :maxLength="30000"
              :loading="loading"
              counter="30000"
            ></v-textarea>
          </v-col>
        </v-row>
        <v-row v-for="n in (attachment.length + 1)" :key="n">
          <v-col cols="12" sm="12" md="12" lg="12">
            <!-- <div
              @dragenter="dragEnter"
              @dragleave="dragLeave"
              @dragover.prevent
              @drop.prevent="dropFile"
            > -->
            <div>
              <v-file-input
                :background-color="isEnter ? 'info' : 'transparent'"
                label="添付"
                v-model="attachment[n-1]"
                dense
                :readonly="false"
                :clearable="true"
                :rules="[]"
                :loading="loading"
                truncate-length="50"
                placeholder="ファイルを選択します。"
                persistent-placeholder
                @change="filterTmpAttachment"
              ></v-file-input>
            </div>
          </v-col>
        </v-row>

        <!-- 個別返答ファイル -->
        <!-- <v-row>
          <v-col cols="3" sm="3" md="3" lg="3">
            <div
            >
              <v-file-input
                :background-color="isEnter ? 'info' : 'transparent'"
                label="個別返答"
                v-model="indivFolder"
                dense
                :readonly="false"
                :clearable="true"
                :rules="[]"
                :loading="loading"
                truncate-length="50"
                placeholder="フォルダを選択します。"
                persistent-placeholder
                webkitdirectory
                multiple
                @change="changeFolder(indivFolder)"
              ></v-file-input>
            </div>
          </v-col>
          <v-col cols="3" sm="3" md="3" lg="3">
            <div
            >
              <v-file-input
                :background-color="isEnter ? 'info' : 'transparent'"
                label="時系列返答"
                v-model="tlFolder"
                dense
                :readonly="false"
                :clearable="true"
                :rules="[]"
                :loading="loading"
                truncate-length="50"
                placeholder="フォルダを選択します。"
                persistent-placeholder
                webkitdirectory
                multiple
                @change="changeFolder(tlFolder)"
              ></v-file-input>
            </div>
          </v-col>
          <v-col cols="3" sm="3" md="3" lg="3" class="text-center">
            <v-btn
              class="btn_font primary" 
              width="250" 
              v-on:click="uploadReplyFile()"
              :disabled="loading || 
                          ( !(indivFolder.length > 0) || (!(tlFolder.length > 0)))"
            >
              <v-icon :small="true">mdi-upload</v-icon>個別返答ファイルアップロード
            </v-btn>
          </v-col>
        </v-row> -->

        <!-- 個別返答先 -->
        <v-row class="mt-5">
          <v-col>
            <h3>個別返答先</h3>
          </v-col>
        </v-row>
        <v-row class="mb-n7">
          <v-col cols="2">
            <v-checkbox
              label="すべて選択"
              v-model="selectAll"
              hide-details
              dense
            ></v-checkbox>
          </v-col>
          <v-col cols="4">
            <v-text-field
              label="検索"
              v-model="searchCondition.searchString"
              dense
              :readonly="false"
              :clearable="false"
              :maxLength="50"
              :loading="loading"
              hide-details
            ></v-text-field>
        </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="12" lg="12">
            <v-data-table
              ref="refDest"
              :headers="headers"
              :items="sendData.dest"
              :loading="loading"
              :dense='true'
              :items-per-page="10"
              :mobile-breakpoint="0"
              :disable-pagination='true'
              :hide-default-footer='true'
              :fixed-header='true'
              disable-sort
              locale="ja-jp"
              loading-text="読込中"
              no-data-text="データがありません。"
              class="table-border send-table"
              :search="searchCondition.searchString"
              height="350px"
            >
              <template v-slot:item="{ item, index }">
                <tr :key="index" class='tr-data'>
                  <td class="center">
                    <v-checkbox class="d-inline-flex" v-model="item.send"></v-checkbox>
                  </td>
                  <td class="left">
                    {{item.companyName}}
                  </td>
                  <td class="left">
                    {{item.officeName}}
                  </td>
                  <td class="center">
                    {{item.memberNo}}
                  </td>
                  <td class="center">
                    {{item.companyId}}
                  </td>
                  <td class="left">
                    {{item.toList}}
                  </td>
                  <td class="center">
                    <v-checkbox class="d-inline-flex" v-model="item.zip" @change="checkZip(index)"></v-checkbox>
                  </td>
                  <td class="left">
                    <v-icon v-if="item.attach1Path != '' && item.attach1Path != null" @click="fileDownload(item.attach1Path, item.attach1Name)">mdi-download-circle</v-icon>{{item.attach1Name}}
                  </td>
                  <td class="left">
                    <v-icon v-if="item.attach2Path != '' && item.attach2Path != null" @click="fileDownload(item.attach2Path, item.attach2Name)">mdi-download-circle</v-icon>{{item.attach2Name}}
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-col>
        </v-row>

        <!-- アクション -->
        <v-row>
          <v-col cols="6" sm="6" md="6" lg="6" class="text-center">
            <v-btn class="btn_font primary" width="200" v-on:click="sendReply()" :disabled="loading || !(sendData.dest.length > 0)" ><v-icon :small="true">mdi-send</v-icon>個別返答メール送信</v-btn>
          </v-col>
          <v-col cols="6" sm="6" md="6" lg="6" class="text-center">
            <v-btn class="btn_font primary" width="200" v-on:click="cancel()" :disabled="loading" >キャンセル</v-btn>
          </v-col>
        </v-row>
      </div>

    </v-form>

    <!-- modal -->
    <modal name="modal-dialog-from" :draggable="false" :clickToClose="false" :scrollable="true" 
                              height="auto" width="80%" id="vm--modal-from">
      <div class="">
        <div class="d-flex modal-header mt-5 justify-space-between">
          <h2>メールアドレス選択</h2>
          <v-icon x-large v-on:click="hideFrom()" :disabled="loading">mdi-close-circle</v-icon>
        </div>
        <div class="modal-body">
          <SelectEmailAddress v-on:closeEvt="hideFrom" v-on:registEvt="updateEmailAddressList" :selectedList="modalSelectedList" :type="emailType" :solo="emailSolo" />
        </div>
      </div>
    </modal>

    <!------------------------------------------------------------------------>
    <!-- ぐるぐる -->
    <modal name="modal-dialog" :draggable="false" :clickToClose="false" :scrollable="true" height="auto" id="vm--modal">
      <div>
        <h3 class="center mt-5 action-message">{{actionMessage}}</h3> 
        <Spinner size="150" class="spinner" ></Spinner>
      </div>
    </modal>
  </v-container>
</template>

<script>
import Debug from '../Lib/Debug'
import dayjs from "dayjs";
import Spinner from 'vue-simple-spinner'
//import Util from '../Lib/Util'
import Message from "../Lib/Message";

import YearPicker from "./common/YearPicker.vue";
import SelectEmailAddress from './SelectEmailAddress.vue';
import MailTemplate from "../model/mailTemplate";
import Download from "../model/download";
import saveAs from "file-saver";

import FactFinding from "../model/factFinding";
import FactFindingSum from "../model/factFindingSum";

export default {
  components: {
    SelectEmailAddress,
    YearPicker,
    Spinner,
  },

  data: () => ({
    valid : true,
    loading: false,

    // ファイル ドラッグアンドドロップ
    isEnter: false,

    // メールアドレス選択
    emailType: 0,
    emailSolo: false,

    // 調査種別リスト
    surveyType: [
      {text: "月次報告データ", value: 1},
      {text: "実態調査", value: 2},
      {text: "電力調査", value: 3},
      {text: "冷媒調査", value: 4},
    ],

    // 対象調査種別
    targetSurveyType: null,

    // 検索条件
    searchCondition: {
      searchString: "",
    },

    // 実態調査年度
    targetYear: "",
    kobetsuFlg: false,

    // テンプレートデータ
    mailData: {},
    templateData: {
      templateId: null,
      type: 0,
      mailFrom: '',
      mailTo: '',
      mailCC: '',
      mailBCC: '',
      subject: '',
      body: '',
      signature: '',
    },
    // メールアドレス一時リスト
    mailDestArray: {
      tmpFromList: [],
      tmpToList: [],
      tmpCCList: [],
      tmpBCCList: [],
    },

    // 添付一時ファイル(ドラッグアンドドロップ用)
    tmpDropFiles: [],

    // テーブルヘッダー
    headers: [
        { text: 'ﾒｰﾙ送信', align: 'center', sortable: true, value: 'send', width: "60px" },
        { text: '会社名', align: 'center', sortable: true, value: 'companyName', width: "150px" },
        { text: '事業所名', align: 'center', sortable: true, value: 'officeName', width: "150px" },
        { text: '会員番号', align: 'center', sortable: true, value: 'memberNo', width: "85px" },
        { text: '登録番号', align: 'center', sortable: true, value: 'companyId', width: "85px" },
        { text: '宛先', align: 'center', sortable: true, value: 'toList', width: "300px" },
        { text: 'ZIP', align: 'center', sortable: true, value: 'zip', width: "60px" },
        { text: '添付 個別返答', align: 'center', sortable: true, value: 'attach1Name', width: "250px" },
        { text: '添付 時系列', align: 'center', sortable: true, value: 'attach2Name', width: "250px" },
    ],

    // メールデータ
    sendData: {
      // 送信対象(事業所一覧)
      dest: [],
    },

    // 添付ファイル
    attachment: [],

    // 個別返答フォルダ
    indivFolder: [],
    // 時系列フォルダ
    tlFolder: [],

    // モーダル用リスト
    modalList: [],
    modalSelectedList: [],

    // 送信対象
    selectMember: [],

    actionMessage: '',
  }),

  // ライフサイクルフック
  created: function () {
    this.targetSurveyType = this.$store.getters.getTargetSurveyType;

    this.init();

    this.getData();
  },

  mounted: async function () {

  },

  methods: {
    async init() {
      Debug.log('function[init]');
      this.loading = true;

      // 実施年
      this.targetYear = dayjs().startOf('year').format().substr(0, 7);

      // // アドレス帳
      // try {
      //   const res = await MemailAddress.search({attribute: 2});
      //   Debug.log(res.data);
      //   if(res.data.length > 0) {
      //     this.emailAddressListMyCompany = res.data;
      //   }
      // } catch (error) {
      //   Message.err(error, "アドレス帳を取得できませんでした");
      // }
      // Debug.log(this.emailAddressListMyCompany);

      // 実施年
      //this.survey.targetYear = dayjs().format().substr(0, 7);
      //Debug.log(this.survey.targetYear);

      this.loading = false;
    },

    /** 表示更新 */
    async getData() {
      Debug.log('function[getData]');
      this.loading = true;

      try {
        // 個別返答のメールテンプレート取得
        const res = await MailTemplate.getMailTemplateDataForType(5);
        Debug.log(res.data);
        // レスポンス確認
        if(res.data.template.length > 0 && res.status == 200) {
          this.templateData = res.data.template[0];
          this.mailDestArray.tmpFromList = res.data.from;
          this.mailDestArray.tmpToList = res.data.to;
          this.mailDestArray.tmpCCList = res.data.cc;
          this.mailDestArray.tmpBCCList = res.data.bcc;
        }
        this.mailData = JSON.parse(JSON.stringify(this.templateData));

        Debug.log2('mailData:', this.mailData);
        Debug.log2('mailDestArray:', this.mailDestArray);

      } catch (error) {
        Message.err(error, "メールテンプレートを取得できませんでした");
      }
      Debug.log(this.tmpFromList);
      this.loading = false;
    },

    // メールアドレス更新
    updateEmailAddressList (type, innerSelectedList, tmpStr) {
      Debug.log('function[updateEvt]');
      this.loading = true;

      // 編集画面での変更を反映する
      switch(type) {
        case 0:
          // 発信者
          //this.mailData.mailFrom = (tmpStr === '') ? ((this.templateData.mailFrom == null || this.templateData.mailFrom == '') ? '' : this.templateData.mailFrom) : tmpStr;
          this.mailData.mailFrom = tmpStr;
          this.mailDestArray.tmpFromList = [innerSelectedList];
          Debug.log(this.tmpFromList);
          break;

        case 1:
          // 宛先
          //this.mailData.mailTo = (tmpStr === '') ? this.templateData.mailTo : ((this.templateData.mailTo == null || this.templateData.mailTo == '') ? tmpStr : this.templateData.mailTo + ',' + tmpStr);
          this.mailData.mailTo = tmpStr;
          this.mailDestArray.tmpToList = innerSelectedList;
          break;

        case 2:
          // CC
          //this.mailData.mailCC = (tmpStr === '') ? this.templateData.mailCC : ((this.templateData.mailCC == null || this.templateData.mailCC == '') ? tmpStr : this.templateData.mailCC + ',' + tmpStr);
          this.mailData.mailCC = tmpStr;
          this.mailDestArray.tmpCCList = innerSelectedList;
          break;

        case 3:
          // BCC
          //this.mailData.mailBCC = (tmpStr === '') ? this.templateData.mailBCC : ((this.templateData.mailBCC == null || this.templateData.mailBCC == '') ? tmpStr : this.templateData.mailBCC + ',' + tmpStr);
          this.mailData.mailBCC = tmpStr;
          this.mailDestArray.tmpBCCList = innerSelectedList;
          break;
      }

      this.loading = false;
    },

    // 調査対象リスト更新
    updateDest (innerSelectedList) {
      Debug.log('function[updateEvt]');
      this.loading = true;

      // 編集画面での変更を反映する
      this.survey.dest = innerSelectedList;
      Debug.log(this.survey.dest);

      this.loading = false;
    },

    // キャンセル
    cancel() {
      if (!confirm("変更をキャンセルします。よろしいですか？")) return;
      this.$router.back();
    },

    // ファイル ドラッグアンドドロップ
    dragEnter() {
      Debug.log('function[dragEnter]');
      this.isEnter = false;
    },
    dragLeave() {
      Debug.log('function[dragLeave]');
      this.isEnter = false;
    },
    dropFile() {
      Debug.log('function[dropFile]');
      // ドロップされたファイルの取得
      this.tmpDropFiles = [...event.dataTransfer.files];
      Debug.log(this.tmpDropFiles);

      // 添付ファイルに追加
      for(let i=0; i < this.tmpDropFiles.length; i++) {
        this.attachment.push(this.tmpDropFiles[i]);
      }
      // 一時ファイルの初期化
      this.tmpDropFiles = [];

      this.isEnter = false;
    },
    filterTmpAttachment() {
      // リストからnullを削除
      Debug.log('function[filterTmpAttachment]');
      this.attachment = this.attachment.filter(v => !!v);
    },

    changeFolder(items) {
      Debug.log('function[changeFolder]');
      Debug.log2('items:', items);

      for(let i=0 ; i<items.length ; i++) {
        Debug.log2('filePath:', items[i].webkitRelativePath);
        // 階層のチェック
        const tmpPath = items[i].webkitRelativePath.split('/');
        if(tmpPath.length > 2) {
          // トップ階層のファイル以外は除外
          Debug.log2('disableFile:', items[i].webkitRelativePath);
          items.splice(i,1);
        }
      }
      Debug.log2('items:', items);
    },

    // 個別返答アップロード
    async uploadReplyFile() {
      // 指定フォルダ内のファイルをサーバにアップロードする
      Debug.log('function[indivUpload]');
      Debug.log2('indivFolder:', this.indivFolder);
      Debug.log2('tlFolder:', this.tlFolder);

      this.loading = true;

      try {
        let res = null;
        res = await FactFinding.uploadReplyFile(this.indivFolder, this.tlFolder);

        if(res.status == 200) {
          Debug.log2('dest:',res.data);
          this.sendData.dest = res.data;
        }
      } catch (error) {
        Message.err(error, "アップロードできませんでした");
      }

      this.loading = false;
    },

    //個別返答送信
    async sendReply() {
      Debug.log('function[sendReply]');
      Debug.log2('selectMember:',this.selectMember);
      if (!this.$refs.form.validate()) {
        alert("入力に誤りがあります。内容をご確認ください。");
        return;
      }

      if (!confirm("個別返答を送信します。よろしいですか？")) return;
      this.loading = true;
      try {
        this.actionMessage = "個別返答を送信しています。\nしばらくお待ちください。"
        this.$modal.show('modal-dialog');

        let res = null;
        res = await FactFinding.sendReply(this.mailData, this.mailDestArray, this.sendData, this.attachment);

        // サーバーサイドのバリデーション 
        if(res.status == 200) {
          const validationMsg = res.data;
          if (validationMsg) {
            let message = "";
            if (Array.isArray(validationMsg)) {
              validationMsg.forEach(m => message += m + '\n')
            } else {
              message = validationMsg;
            }
            alert(message);

            this.loading = false;
            return;
          } else {
            // 送信成功
            alert("送信しました。");
            this.$router.back();
          }
        }
      } catch (error) {
        Message.err(error, "送信できませんでした");
      } finally {
        this.$modal.hide('modal-dialog');
      }

      this.loading = false;
    },

    /** アドレス帳モーダル表示用 */
    showEmailAddress (type, solo, selectedList) {
      Debug.log('function[show]');

      this.emailType = type;
      this.emailSolo = solo;
      this.modalSelectedList = selectedList;
      this.$modal.show('modal-dialog-from');
    },
    hideFrom : function () {
      Debug.log('function[hide]');
      this.$modal.hide('modal-dialog-from');
    },

    /** 個別返答集計 */
    async kobetsuFactFinding() {
      //this.kobetsuFlg = true;
      try {
        this.actionMessage = "個別返答集計データを作成しています。\nしばらくお待ちください。"
        this.$modal.show('modal-dialog');

        const res = await FactFindingSum.kobetsu(this.targetYear);

        if (res.status == 200 && res.data) {
          // 作成に成功したらメール送信表示
          Debug.log2('res.data',res.data);
          // this.$nextTick(() => {
            // 送信リスト
            this.sendData.dest = res.data;
          // });
          if(this.sendData.dest.length > 0) {
            this.kobetsuFlg = true;
            alert("個別返答集計データを作成しました。");
          } else {
            alert("個別返答対象の事業所がありません。");
          }


        }
      } catch (error) {
        Message.err(error, "個別返答集計データを作成できませんでした");
      } finally {
        this.$modal.hide('modal-dialog');
      }

    },

    /** ファイルダウンロード */
    fileDownload(filepath, fileName) {
      Debug.log("function[filePreview]");
      if (filepath != "" && filepath != null) {
        Debug.log("Download:[" + filepath + "]");
        Download.download(filepath)
            .then(res => {
              saveAs(res.data, fileName);
            })
            .catch((err) => {
              Message.err(err, "ダウンロードできませんでした");
            })
      }
    },

    /** ZIPチェックボックス */
    checkZip(index) {
      if(this.sendData.dest[index].zip) {
        // ZIPにチェックが入っている場合、添付ファイルをZIPのものに置き換える
        // 個別返答
        this.$set(this.sendData.dest[index], "attach1Path", this.sendData.dest[index].indivFilePathZip);
        this.$set(this.sendData.dest[index], "attach1Name", this.sendData.dest[index].indivFileNameZip);
        // 時系列
        this.$set(this.sendData.dest[index], "attach2Path", this.sendData.dest[index].tlFilePathZip);
        this.$set(this.sendData.dest[index], "attach2Name", this.sendData.dest[index].tlFileNameZip);
      } else {
        // ZIPにチェックが入っていない場合、添付ファイルを通常(ZIPではない)のものに置き換える
        // 個別返答
        this.$set(this.sendData.dest[index], "attach1Path", this.sendData.dest[index].indivFilePath);
        this.$set(this.sendData.dest[index], "attach1Name", this.sendData.dest[index].indivFileName);
        // 時系列
        this.$set(this.sendData.dest[index], "attach2Path", this.sendData.dest[index].tlFilePath);
        this.$set(this.sendData.dest[index], "attach2Name", this.sendData.dest[index].tlFileName);
      }
    },
  },

  computed: {
    filterItems() {
      if (this.sendData.dest.length > 0) {
        return this.$refs.refDest.$children[0].filteredItems;
      } else {
        return [];
      }
    },

    selectAll: {
      get: function () {
        Debug.log('selectAll get');
        return this.filterItems ? this.filterItems.length > 0 && 
                                    this.filterItems.filter(function(value) { return value.send == true; }).length == this.filterItems.length : false;
      },
      set: function (newValue) {
        Debug.log2('newValue:', newValue);
        Debug.log2('filterItems:', this.filterItems);
        let tmpSelectedList = [];
        this.filterItems.forEach(function (el, index) {
          tmpSelectedList.push(index);
        });

        for(let i=0 ; i<tmpSelectedList.length ; i++) {
          this.$set(this.filterItems[tmpSelectedList[i]], 'send', newValue);
        }
      }
    },
  },

  watch: {

  },
};
</script>

<style scoped>

.disablecolor {
  background: #bbbbbb;
}

.send-table >>> .v-data-table__wrapper {
  max-height: 500px;
}

/* ぐるぐる */
.action-message {
  white-space: pre-line;
}
.spinner {
  margin: 50px;
}

/** テーブルの縦横固定 */
.v-data-table >>> th, td {
  padding-left: 10px !important;
  padding-right: 10px !important;
  overflow-wrap: break-word;
  word-break: keep-all;
}
.v-data-table >>> td {
  height: 40px !important;
}
.v-data-table >>> td > div{
  height: 60px !important;
}
.v-data-table--fixed-header >>> th:nth-child(1) {
    position: sticky !important;
    position: -webkit-sticky !important;
    left: 0;
    top: 0;
    z-index: 3;
}
.v-data-table--fixed-header >>> .tr-data td:nth-child(1) {
    position: sticky !important;
    position: -webkit-sticky !important;
    left: 0;
    z-index: 2;
    background: #ffffff;
}
.v-data-table--fixed-header >>> th:nth-child(2) {
    position: sticky !important;
    position: -webkit-sticky !important;
    left: 60px;
    top: 0;
    z-index: 3;
}
.v-data-table--fixed-header >>> .tr-data td:nth-child(2) {
    position: sticky !important;
    position: -webkit-sticky !important;
    left: 60px;
    z-index: 2;
    background: #ffffff;
}
.v-data-table--fixed-header >>> th:nth-child(3) {
    position: sticky !important;
    position: -webkit-sticky !important;
    left: 210px;
    top: 0;
    z-index: 3;
}
.v-data-table--fixed-header >>> .tr-data td:nth-child(3) {
    position: sticky !important;
    position: -webkit-sticky !important;
    left: 210px;
    z-index: 2;
    background: #ffffff;
}
.v-data-table--fixed-header >>> th:nth-child(4) {
    position: sticky !important;
    position: -webkit-sticky !important;
    left: 360px;
    top: 0;
    z-index: 3;
}
.v-data-table--fixed-header >>> .tr-data td:nth-child(4) {
    position: sticky !important;
    position: -webkit-sticky !important;
    left: 360px;
    z-index: 2;
    background: #ffffff;
}
.v-data-table--fixed-header >>> th:nth-child(5) {
    position: sticky !important;
    position: -webkit-sticky !important;
    left: 445px;
    top: 0;
    z-index: 3;
}
.v-data-table--fixed-header >>> .tr-data td:nth-child(5) {
    position: sticky !important;
    position: -webkit-sticky !important;
    left: 445px;
    z-index: 2;
    background: #ffffff;
}
</style>
